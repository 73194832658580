import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import { MetadataReadonly } from "../types/contentfulTypes"
import PricingSection from "../components/sections/PricingSection"
import TestimonialsSection from "../components/sections/TestimonialsSection"
import FaqSection from "../components/sections/FaqSection"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const Pricing = (props: Props) => {
  const { data } = props

  return (
    <Layout {...data}>
      <Wrapper>
        <PricingSection />
        <TestimonialsSection />
        <FaqSection />
      </Wrapper>
    </Layout>
  )
}

export default Pricing

export const query = graphql`
  query PricingQuery {
    contentfulMetadata(slug: { eq: "pricing" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  padding: 200px 0px;

  .pricing {
    margin: 0 auto;
    padding-bottom: 244px;
  }
`
